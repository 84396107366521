import {NgModule} from '@angular/core'

const data: any[] = [];
@NgModule({
    imports:data,
    exports:data
})

export class MaterialModule{

}