import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

export enum ModalType {
  Confirmation,
  Delete,
}

@Component({
  selector: "app-modal--cancel-confirm",
  templateUrl: "./modal-cancel-confirm.component.html",
  styleUrls: ["./modal-cancel-confirm.component.scss"],
})
export class ModalCancelConfirmComponent implements OnInit {
  public onClose: Subject<any> = new Subject<any>();
  public message: string = "";
  public modalType: ModalType = ModalType.Delete;
  ModalType = ModalType;
  public icon: string = "feather icon-x-circle fs-1 text-danger";

  inputForm = this.formBuilder.nonNullable.group({
    comment: [""],
  });

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
  }

  modalConfirm(): void {
    this.onClose.next(this.inputForm.controls);
    this.modalRef.hide();
  }

  modalDecline(): void {
    this.onClose.next("");
    this.modalRef.hide();
  }
}
