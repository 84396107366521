import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';

if (environment.production) {
  enableProdMode();
}

if((window as any).ENABLE_PROD_MODE){
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule,{
    providers: [
      {
        provide: LIGHTBOX_CONFIG,
        useValue: {
          keyboardShortcuts: false,
          exitAnimationTime: 1000
        } as LightboxConfig
      }
    ]
  })
  .catch((err) => console.error(err));
