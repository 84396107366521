<ng-scrollbar (clickOutside)="navMob()" style="height: calc(100vh - 56px)">
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigations">
        <app-nav-group *ngIf="item.type === 'group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type === 'collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type === 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <!-- <app-card [hidHeader]="true" cardClass="text-center">
      <i class="feather icon-sunset f-40"></i>
      <h6 class="mt-3">Help?</h6>
      <p>Please contact us on our email for need any support</p>
      <a href="javascript:" target="_blank" class="btn btn-primary btn-sm text-white m-0">Support</a>
    </app-card> -->
    <!-- <div class="version">
      <label disabled class="pe-auto">v{{ currentApplicationVersion }}</label>
    </div> -->
  </div>
</ng-scrollbar>
