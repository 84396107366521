// Angular Import
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// project import
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CardComponent } from "./components/card/card.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";

// third party
import { NgScrollbarModule } from "ngx-scrollbar";
import { NgClickOutsideDirective } from "ng-click-outside2";
import "hammerjs";
import "mousetrap";

// bootstrap import
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { AgCustomDropdownComponent } from "./components/Button/ag-custom-dropdown/ag-custom-dropdown.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    BreadcrumbComponent,
    NgbDropdownModule,
    BsDropdownModule.forRoot(),
    NgbNavModule,
    NgbModule,
    NgScrollbarModule,
    NgClickOutsideDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    BreadcrumbComponent,
    SpinnerComponent,
    NgbModule,
    NgbDropdownModule,
    NgbNavModule,
    NgScrollbarModule,
    NgClickOutsideDirective,
    AgCustomDropdownComponent,
  ],
  declarations: [SpinnerComponent, AgCustomDropdownComponent],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class SharedModule {}
